import { appModes } from "@/appModes";
import appSetupFactory from "@/Study/main";

const firebaseConfig = {
    apiKey: "AIzaSyAgmKoDnnYeW-v-tHbr2tsLap0n9FJo0UQ",
    authDomain: "corona-study-334515.firebaseapp.com",
    projectId: "corona-study-334515",
    storageBucket: "corona-study-334515.appspot.com",
    appId: "1:911997890003:web:71cbbb98de3ad1027c9326"
};

const token = '/token/:token';
const additionalRoutes = [
    { path: `${token}/agreement`, component: () => import('@/CoronaStudy/AgreementSection.vue'), name: 'agreement' },
    { path: `${token}/filled-recently`, component: () => import('@/CoronaStudy/FilledRecently.vue') },
    { path: `${token}/filled`, component: () => import('@/CoronaStudy/Filled.vue') },
]

const appSetup = appSetupFactory(firebaseConfig, appModes.CORONA_STUDY, additionalRoutes);
export default appSetup;